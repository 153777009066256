<template>
  <v-card raised elevation="6" class="mt-1">
    <v-card-title>
      <h4
        style="
          font-size: 15px;
          text-transform: uppercase;
          font-weight: 100;
          text-decoration: underline;
        "
      >
        {{ $t("expenses.bySuppliers") }}
      </h4>
      <v-spacer></v-spacer>
      <v-btn @click="graphic = !graphic" :outlined="graphic" height="30px"
        >Tabla</v-btn
      >
    </v-card-title>
    <v-card-text v-if="graphic"> <BarChartBySuppliers /> </v-card-text>
    <v-data-table
      v-else
      :headers="headersExpensesBySuppliers"
      :items="list"
      :items-per-page="5"
      id="expensesBySuppliers-table"
    >
      <template v-slot:item.supplier_name="{ item }">
        {{ item.supplier_name }}
      </template>
      <template v-slot:item.cantidad="{ item }">
        {{ $n(item.cantidad) }}€
      </template>
    </v-data-table>
  </v-card>
</template>

<script>
import { mapActions, mapMutations, mapState } from "vuex";
export default {
  name: "ExpensesBySuppliers",
  components: {
    BarChartBySuppliers: () =>
      import("@/components/stats/expenses/graphics/ExpensesBySuppliersGraphic"),
  },
  ...mapState("stats", ["lists"]),

  computed: {
    //METODO / VARIABLE
    list() {
      return this.$store.state.stats.lists.bySuppliers;
    },
  },
  data() {
    return {
      graphic: true,
      headersExpensesBySuppliers: [
        {
          text: this.$t("name"),
          align: "center",
          sortable: false,
          value: "supplier_name",
        },
        {
          text: this.$t("quantity"),
          align: "center",
          sortable: false,
          value: "cantidad",
        },
      ],
    };
  },
};
</script>
